import { signal } from '@preact/signals';
import SearchInput from './Search';
import { CSSTransition } from 'preact-transitioning';

export const isOpen = signal(false);
export function SearchWrapper() {
  return (
    <CSSTransition in={isOpen.value} duration={300} classNames={'fade'}>
      <SearchInput />
    </CSSTransition>
  );
}
