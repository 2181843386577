import c from 'clsx';
import Podcast from '@/components/Podcast/Podcast';
import { useDebouncedState } from '@/shared/hooks/use-debounced-state';
import axios, { AxiosResponse } from 'axios';
import { createRef } from 'preact';
import { useEffect, useState } from 'preact/hooks';

interface SearchResponse {
  itunes_id: string;
  title: string;
  summary: string;
  images: Images;
  genre: string;
  url: string;
  highlight: any[];
}

interface Images {
  large: string;
  small: string;
  medium: string;
  mini: string;
  srcset: string;
}

const endpoint = window.searchEndpoint ? window.searchEndpoint : '/api/search';

interface SearchClient {
  controller: AbortController | null;
  get(url: string): {
    promise: Promise<AxiosResponse>;
    controller: AbortController;
  };
  search(query: string): Promise<SearchResponse[]>;
  abortPendingRequest(): void;
}

const client: SearchClient = {
  controller: null,
  abortPendingRequest() {
    if (this.controller) {
      this.controller.abort();
    }
  },
  get(url: string) {
    const controller = new AbortController();
    const promise = axios.get(url, { signal: controller.signal });
    return {
      promise,
      controller,
    };
  },
  async search(value: string): Promise<SearchResponse[]> {
    this.abortPendingRequest();

    const { promise, controller } = this.get(
      `${endpoint}?query=${encodeURIComponent(value)}`
    );
    this.controller = controller;

    try {
      const { data } = await promise;
      return data.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
};

export default function Search(props: { className?: string }) {
  const inputRef = createRef();
  const [searchQuery, setSearchQuery] = useDebouncedState('', 350);
  const [results, setSearchResults] = useState<SearchResponse[]>([]);

  useEffect(() => {
    if (searchQuery.length > 2) {
      client
        .search(searchQuery)
        .then((data) => {
          setSearchResults(data);
        })
        .catch((e) => {
          console.log(e)
        });
    } else {
      return;
    }
  }, [searchQuery, open]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  return (
    <div class={c('p-index transition-opacity duration-300', props.className)}>
      <section class="max-w-[90%] mx-auto mt-6 lg:mt-12 h-auto flex flex-col">
        <input
          defaultValue={searchQuery}
          onInput={(e) => setSearchQuery(e.currentTarget.value)}
          class="p-input"
          ref={inputRef}
          placeholder="Sök efter podcast"
        />
        <div class="items-grid max-w-3xl w-full self-center">
          <div class="row">
            {results.map((result) => (
              <Podcast
                key={result.url}
                title={result.title}
                url={result.url}
                imageSrc={result.images.medium}
                genre={result.genre}
              />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}
