import { h, render } from 'preact';
import { SearchWrapper, isOpen } from './SearchWrapper';

const Search = () => {
  const el = document.getElementById('search');
  if (el) {
    render(h(SearchWrapper, null), el);
  }
};

export { isOpen };
export default Search;
