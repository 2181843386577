import 'intersection-observer';
import createLeeAdObserver from '~/utils/leeadObserver';
import 'lazysizes';
import FontFaceObserver from 'fontfaceobserver';
import search, { isOpen } from './components/Search';
import '~/sentry';

if (window.NodeList && !NodeList.prototype.forEach) {
  // eslint-disable-next-line func-names
  NodeList.prototype.forEach = function (callback, thisArg) {
    // eslint-disable-next-line no-param-reassign
    thisArg = thisArg || window;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}
const adObserver = createLeeAdObserver();

function cleanUrl() {
  if (window.location.search.indexOf('pid') > -1) {
    window.history.replaceState({}, document.title, window.location.pathname);
  }
}

window.addEventListener('DOMContentLoaded', () => {
  cleanUrl();
  window.addObserver = adObserver;
  const ads = document.querySelectorAll('[data-cnt][data-pbid]');
  adObserver.add(ads);
  const cookieSettings = document.querySelectorAll('.ot-cookie-settings');
  cookieSettings.forEach((node) => {
    node.addEventListener('click', (e) => {
      e.preventDefault();
      if (window.__tcfapi) {
        window.__tcfapi('displayConsentUi', 2, () => {});
      }
    });
  });
});

new FontFaceObserver('Lato', { weight: 100 }).load().then(() => {
  document.documentElement.className += ' fonts-loaded';
});

const keyUpEvents = {
  events: [],
  subscribe(event) {
    this.events.push(event);
  },
  init() {
    document.addEventListener('keyup', (e) => {
      this.events.forEach((event) => event(e));
    });
  },
};
// Lazy load images
window.lazySizesConfig = window.lazySizesConfig || {};
// Expands the calculated visual viewport area in all directions, so that elements can be loaded before they become visible. Default 370-500
window.lazySizesConfig.expand = 400;
// page is optimized for fast onload event
window.lazySizesConfig.loadMode = 1;

window.lazySizes.init();

/* Toggle menu open/close */
function classToggle() {
  document.body.classList.toggle('menu-is-closed');
  document.body.classList.toggle('menu-is-open');
}

const menuToggle = document.getElementById('menu-toggle');
const categoryToggle = document.getElementById('category-toggle');
const searchToggle = document.getElementById('search-toggle');

keyUpEvents.subscribe(({ keyCode = 0 }) => {
  if (
    keyCode === 27 &&
    document.documentElement.classList.contains('menu-is-open')
  ) {
    classToggle();
  }
});

const closeSearch = () => {
  isOpen.value = false;
  document.documentElement.classList.remove('search--is-open');
  window.dispatchEvent(new Event('search:closed'));
};

const openSearch = () => {
  isOpen.value = true;
  document.documentElement.className += ' search--is-open';
  window.dispatchEvent(new Event('search:open'));
};

// watching for keyevents
// to be able to catch shift + /.
let k = [];
const d = (e) => {
  k[e.keyCode] = e.type === 'keydown';
  if (k[55] && k[16]) {
    e.preventDefault();
    openSearch();
    k = [];
  }
  setTimeout(() => {
    k = [];
  }, 250);
};

document.addEventListener('keydown', d);
document.addEventListener('keyup', d);

if (menuToggle) {
  menuToggle.addEventListener('click', () => {
    closeSearch();
    classToggle();
  });
}

if (categoryToggle) {
  categoryToggle.addEventListener('click', classToggle);
}

if (searchToggle) {
  const isOpen = () =>
    document.documentElement.classList.contains('search--is-open');
  keyUpEvents.subscribe(({ keyCode = 0 }) => {
    if (keyCode === 27 && isOpen()) {
      closeSearch();
    }
  });
  searchToggle.addEventListener('click', (el) => {
    if (document.documentElement.classList.contains('menu-is-open')) {
      classToggle();
    }

    if (isOpen()) {
      closeSearch();
    } else {
      openSearch();
    }
  });
}

/* detect touch / no-touch */
function isTouchDevice() {
  return (
    'ontouchstart' in window ||
    navigator.MaxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  );
}

if (isTouchDevice()) {
  document.documentElement.className += ' touch';
} else {
  document.documentElement.className += ' no-touch';
}

keyUpEvents.init();

search();
